import { useQuery } from 'react-query';
import { handleClientAccess } from '../helpers/helpFunctions';
import axios from 'axios';
import { apiUrl, merchantContext } from '../../DataProvider';
import { useContext } from 'react';

const baseUrl = process.env.REACT_APP_CREDENCIAL; 
const baseUrlApiSocios = process.env.REACT_APP_API_SOCIO; 

export const getPerfilSocio = async (queryKey) => {
  //Kevin: Le agregue 'source' a queryKey , para saber su procedencia, si es externo o no.
  const external = queryKey?.source;
  const dni = queryKey.queryKey[1];
  const urlNoSource = `${baseUrl}cd/perfil_socio?documento=${dni}`;
  const url = `${baseUrl}cd/perfil_socio?source=${external}&documento=${dni}`;
  const token = localStorage.getItem('auth');
  const tokenSend = await JSON.parse(token)?.token;
  return await axios({
    url: queryKey?.source ? url : urlNoSource,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
      'access-control-expose-headers' : '*',
      'X-Access-Token': tokenSend,
    }
  });
};

export const getPerfilSocioArray = async (queryKey) => {
  const params = queryKey.queryKey[1];
  const url = `${baseUrl}cd/perfil_socio/search?${params}`;
  const token = localStorage.getItem('auth');
  const tokenSend = await JSON.parse(token)?.token;
  
  return await axios({
    url: url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
      'access-control-expose-headers' : '*',
      'X-Access-Token': tokenSend,
    }
  });
};

export const getAuth = async (queryKey) => {
  const dni = queryKey.queryKey[1];
  const token = localStorage.getItem('auth');
  const tokenSend = await JSON.parse(token)?.token;
  return await axios({
    url: `${baseUrl}/cd/autenticacion_manual`,
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
      'access-control-expose-headers' : '*',
      'X-Access-Token': tokenSend,
    },
    data: {'documento': dni},
    method: 'POST'
  });
};

export const getDevice = async (queryKey) => {
  const dni = queryKey.queryKey[1];
  const token = localStorage.getItem("auth");
  const tokenSend = await JSON.parse(token)?.token;

  const postConfig = {
    url: `${baseUrlApiSocios}api/auth/reset-device/${dni}`,
    headers: {
      "Content-Type": "application/json",
      "api-key": "sp2024",
    },
    method: "POST",
  };

  try {
    const response = await axios(postConfig);
    if (response.status === 200) {
      const secondResponse = await axios({
        url: `${baseUrl}/cd/cambio_dispositivo`,
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "access-control-expose-headers": "*",
          "X-Access-Token": tokenSend,
        },
        data: { documento: dni },
        method: "POST",
      });
      console.log("Second response data:", secondResponse.data);
      return secondResponse.data;
    } else {
      console.error("Status arriba 200");
      return {
        status: 400,
        message: 'Bad Request',
      };
    }
  } catch (error) {
    console.error("ERROR POST API SOCIO RESPONSE:", error);
    return {
      status: 400,
      message: 'Bad Request',
    };
  }
};

export const getCobros = async (queryKey) => {
  const dni = queryKey.queryKey[1];
  const token = localStorage.getItem('auth');
  const tokenSend = await JSON.parse(token)?.token;
  return await axios({
    url: `${apiUrl}/cobros_adicionales?filter={"documento":${JSON.stringify(dni)}}`,
    headers: {
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*',
      'access-control-expose-headers' : '*',
      'X-Access-Tokens': tokenSend,
      'context-merchant': queryKey.queryKey[2]
    },
    method: 'GET'
  });
};