
import { Box, Button, CircularProgress, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
import { useQuery } from 'react-query';
import CardBasic from '../../Components/UI/Show/CardBasic';
import TableMinimal from '../../Components/UI/Show/TableMinimal';
import { createNotification, dateFormat, getPermissions } from '../helpers/helpFunctions';
import { merchantContext, merchantsInToken } from '../../DataProvider';
import ModalCustom from '../../Components/UI/ModalCustom';
import moment from 'moment';
import ButtonAws from '../../Components/CustomFields/ButtonAws';
import { theme } from '../../Theme/theme';
import { styleTabbleData, styleTableHead } from '../../Theme/useStyle';
import { AptoMedicoComponent, AsideMenuActions, FechaDeVigenciaComponent, greaterThan } from './SearchComponents';
import { getAuth, getCobros, getDevice, getPerfilSocioArray,getPerfilSocio } from './requests';
import { CobrosDataGrid } from './SearchComponents/CobrosDataGrid';
import { InitialArraySearch } from './SearchComponents/InitialArraySearch';
import axios from 'axios';

const Busqueda = () => {

  const doc = localStorage.getItem('documento');
  const [dni, setDni] = useState(doc ? doc : null);
  const [err, setErr] = useState(null);
  const [open, setOpen] = useState(false);
  const [socio, setSocio] = useState(null);
  const [socios, setSocios] = useState(null);
  const [search, setSearch] = useState(null);
  const [oneSearch,setOneSearch] =useState(null);
  const [openAM, setOpenAM] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [aptoFecha, setAptoFecha] = useState(null);
  const [adicionales, setAdicionales] = useState([]);
  const [loadingApto, setLoadingApto] = useState(false);
  const [openAdicionales, setOpenAdicionales] = useState(false);
  const [openDispositivos, setOpenDispositivos] = useState(false);
  const dataProvider = useDataProvider();
  const refButton = useRef();
  const permis = getPermissions();
  const redirect = useRedirect();
  const { getMerchant, setMerchant: setMerchantContext} = useContext(merchantContext);
  console.log(doc);
  // Requests

  const {refetch: refetchAuth} = useQuery(
    ['refetchAuth', socio?.documento], getAuth,
    {
      onSuccess: (data) => createNotification('success', 'El dispositivo ya fue autorizado, ya puede ser utilizado para poder entrar.'),
      onError: (err) => {
        console.log(err?.response);
        createNotification('error', err?.response?.data?.details);
      },
      enabled: false
    }
  );

  const {isLoading: isLoadingDevice, refetch: refetchDevice} = useQuery(
    ['cd', socio?.documento], getDevice,
    {
      onSuccess: (data) => createNotification('success', 'El dispositivo fue reseteado, ya pueden cambiar de dispositivo para entrar a sportclub.'),
      onError: (err) => createNotification('error', err?.response?.data?.message),
      enabled: false
    }
  );
  
  const {refetch: refetchCobros, isFetching: isFetchingCobros} = useQuery(
    ['refetchCobros', Number.isInteger(dni) ? JSON.stringify(dni) : dni, getMerchant()?.id], getCobros,
    {
      onSuccess: ({data: {data} = {}}) => {
        setAdicionales(data);
      },
      onError: (err) => {
        createNotification('error', err?.response?.data?.details);
      },
      enabled: false
    }
  );

  // START KEVIN : El fetch de Un socio en caso de tener data.length 1
  const {
    refetch: refetchOne,
    isFetching: isFetchingOne,
    error: errorOne,
  } = useQuery(['perfil_socio', oneSearch && oneSearch], getPerfilSocio, {
    onSuccess: ({ data }) => {
      setErr(false);
      setSocios(null);
      let socioMerchId = data?.seller_merchant_id || data?.plan_details?.merchant_id;
      // 1* Verifico si es externo a BUENCLUB, si lo es DISABLED ver socio.
      if(data?.externo === true){
        setShowLink(false);
      }else{
        // 2* Verifico si tiene permisos de superadmin o owner, si lo es HABILITO ver socio.
        if(permis === 'superadmin' || permis === 'owner'){
          setShowLink(true);
        }else{
          if(data?.id || data['_id']){
            // 3* Por cada SELLER de los que tiene el ADMIN actual >
            for (let merch of merchantsInToken) {
              // Verifico si coincide con el ID del SELLER del SOCIO, si es asi HABILITO el ver socio.
              if(socioMerchId === merch.id){
                setShowLink(true);
                break;
              }else{
                // Si no coincide , DISABLED ver socio.
                setShowLink(false);
              }
            }
          }else{
            setShowLink(false);
          }
        }
        // if(data?.id || data['_id'] && (!data?.externo || data?.source !== 'Proclub') && ([merchId, ''].includes(getMerchant()?.id) || getMerchant()?.negocio_central)) {
        
      }
      setDni(data?.documento ? data.documento : '');
      return setSocio(data);
    },
    onError: (err) => {
      setErr(true);
      throw new Error(err);
    },
    enabled: false,
  });
  // END KEVIN
  const {refetch, isFetching, error} = useQuery(['perfil_socio', search],
    getPerfilSocioArray, // Ruta perfil socio para busqueda por lista
    //  getPerfilSocio, // Ruta perfil socio para busqueda por documento
    {
      onSuccess: ({data}) => {
        setErr(false);
        if(data?.length === 0) return setErr(true);
        if(data?.length === 1) {
          // Kevin: Se obtiene el documento del primer socio, en caso de que se busque por email o apellido.
          //  Luego se utiliza el dni para obtener el perfil del socio.
          let dniObtained = data[0]?.documento;
          setOneSearch(dniObtained);
          const waitForOneSearch = async () => {
            await new Promise((resolve) => setTimeout(resolve, 0)); // Pequeño retardo para permitir la actualización del estado
            refetchOne();
          };
  
          waitForOneSearch();

        }else{
          return setSocios(data);
        }
      },
      onError: (err) => {
        console.log('err');
        setErr(true);
        throw new Error(err);
      },
      enabled: false
    }
  );
  
  // Handlers
  const fetchHandler = (e) => {
    let value = e.target.value;
    let passportRegex = /\d/;
    if(value.includes('@')) {
      console.log('Es un mail');
      return setSearch(`mail=${value}`);
    };
    if(passportRegex.test(value)) {
      setDni(value); 
      return setSearch(`documento=${value}`), setOneSearch(value);
    }
  
    console.log('Es un apellido');
    return setSearch(`apellido=${value}`);
  };
  const handleRefetch = () => { setSocio(); refetch(); setOpen(true); setOpenAdicionales(false);};
  const handleRedirect = (e) => {localStorage.setItem('documento', e);redirect('create', 'accesos');};
  const handleClose = () => { setLoadingApto(false);setOpenAM(false);};
  const handleApto = (url) => {setSocio({...socio, apto_medico: {...socio.apto_medico, url: url}});};
  const handleDispositivo = () => { refetchDevice();};
  const handleLink = () => {
    for (let merch of merchantsInToken){
      if(socio?.seller_merchant_id && socio?.seller_merchant_id === merch.id) {
        setMerchantContext(merch);
        break;
      }
      if(socio?.plan_details?.merchant_id && socio?.plan_details?.merchant_id === merch.id) {
        setMerchantContext(merch);
      };
    }
    redirectShow(`/socios/${socio?.['_id']}/show`);
  };
  
  const redirectShow = useCallback((url) => {
    return redirect(url, {
      headers: new Headers({
        'Cache-Control': 'no-cache'
      })
    });
  },[]);
  const handleAptoMedico = () => {
    setLoadingApto(true);
    let fecha = aptoFecha.split('-');
    let newFecha = `${fecha[0]}/${fecha[1]}/${fecha[2]}`;
    let data = {...socio.apto_medico, fecha_vigencia: newFecha};
    delete data.status;
    dataProvider.update('apto_medico', {id: socio?.['_id'], data: data})
      .then(res => {
        setOpenAM(false);
        setLoadingApto(false);
        setSocio({...socio, apto_medico: {...socio.apto_medico, fecha_vigencia: newFecha}});
        createNotification('success', 'Apto médico editado con exito.');
      })
      .catch(err => {
        setLoadingApto(false);
        createNotification('error', err?.body?.msg);
        throw new Error(err);
      });
  };

  useEffect(() => {
    if(doc) {
      setDni(doc);
      setSearch(`documento=${doc}`);
      setOneSearch(doc);
      setTimeout(() =>  handleRefetch(), 200);
    }  
    setTimeout(() => localStorage.removeItem('documento'), 200);
  },[]);

  const zonas = {'Elite': '-1','Total': '1','Plus': '2','Local': '7'};
  const nivelDeAcceso = Object.values(zonas)?.map((item,i) => item === socio?.plan_details?.nivel_de_acceso && Object.keys(zonas)[i]);
  const sedeName = socio?.sede_socio_name ?? 'SportClub Central';
  const dataArr = [
    {key: 'Nombre', value: socio?.nombre},
    {key: 'Apellido', value: socio?.apellido},
    {key: 'Documento', value: socio?.documento},
    {key: 'Email', value: socio?.email},
    socio?.telefono && {key: 'Teléfono', value: socio?.telefono},
    {key: 'Estado', value: socio?.externo && greaterThan(socio?.fecha_vigencia) ? 'activo' : socio?.externo && !greaterThan(socio?.fecha_vigencia) ? 'inactivo' : socio?.status},
    {key: 'Sede', value: sedeName},
    {key: 'Origen', value: socio?.externo ? 'Proclub' : 'BuenClub'},
    // {key: 'Origen', value: socio?.externo},
    {key: 'Fecha de Vigencia', custom: true, value: <FechaDeVigenciaComponent fecha={socio?.fecha_vigencia} />},
    {key: 'Fecha de Apto Médico', custom: true, value: <AptoMedicoComponent aptoMedico={socio?.apto_medico?.fecha_vigencia} />},
    {key: 'Nivel de Acceso', value: (socio?.externo && !nivelDeAcceso.every((e) => e === false)) ? nivelDeAcceso.find(item => item !== false && item) : socio?.externo && !nivelDeAcceso ? socio?.plan_details?.nivel_de_acceso : socio?.plan_details?.nivel_de_acceso},
    socio?.deuda_promocion ? 
      {key: 'Deuda promoción', value: !socio?.externo ? `$ ${socio?.deuda_promocion.toString()}` : `${socio?.deuda_promocion.toString()} periodo.`} : null,
    socio?.deuda_arancel ?  
      {key: 'Deuda arancel', value: !socio?.externo ? `$ ${socio?.deuda_arancel.toString()}` : `${socio?.deuda_arancel.toString()} periodo.`} : null,
    
    {
      label: 'Registro de Cobros Adicionales', 
      collapse: true,
      open: openAdicionales, 
      collapseAction: () => {refetchCobros(); setOpenAdicionales(!openAdicionales);},
      value: 
              (socio && adicionales.length > 0 && !isFetchingCobros) ? (
                <CobrosDataGrid refetchCobros={refetchCobros} redirect={redirect} adicionales={adicionales} />

              ) : (adicionales.length === 0 && !isFetchingCobros)? (
                <TableBody>
                  <TableRow style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gridTemplateColumns: '1fr'}}>
                    <TableCell style={{...styleTabbleData, fontSize:'1.2em', padding: '20px'}} >No existen registros</TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <Box sx={{width: '100%'}}>
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                </Box>
              )
    },
    ((permis === 'superadmin' || permis === 'owner') && socio?.habilitaciones_cambio?.length > 0) ? 
      {label: 'Historial cambios de Dispositivos', open: openDispositivos, collapse: true, collapseAction: () => setOpenDispositivos(!openDispositivos),
        value: 
      <Box style={{with: '100%'}}>
        <Table style={{width: '100'}}>
          <TableHead>
            <TableRow  style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
              <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}} >Fecha</TableCell>
              <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}} >Usuario</TableCell>
            </TableRow>
          </TableHead>
          {
            socio && socio?.habilitaciones_cambio?.map((item, i) => (
              <TableBody>
                <TableRow style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}} key={i}>
                  <TableCell style={{...styleTabbleData, padding: '10px 0 10px 10px'}} >{dateFormat(item?.fecha)}</TableCell>
                  <TableCell style={{...styleTabbleData, padding: '10px 0 10px 10px'}}>{item?.modified_by}</TableCell>
                </TableRow>
              </TableBody>
            ))
          }
        </Table>
      </Box>
      } :
      null,
  ];
  return(
    <>
      <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', marginTop: '50px', marginBottom: '100px'}}>
        <CardBasic styleCardSx={{minWidth:'750px'}} title={'Buscar socio en red SportClub'}>
          <Box style={{display: 'flex', justifyContent: 'space-between', height: 'auto', alignItems: 'center' }}>
            <TextField type='tel' label="Documento, apellido, email" variant={'outlined'} InputLabelProps={{
              variant: 'outlined'
            }} size='small'
            onChange={fetchHandler} id='search_socio_dni' style={{width: 'auto'}}/>
            <Button size="medium" variant='outlined' color='primary' ref={refButton} onClick={handleRefetch}>Buscar</Button>
          </Box>
        </CardBasic>
        { open && (
          <>
            <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
              <Box style={{display: 'grid', gridTemplateColumns: '1fr 2fr 1fr'}}>
                <Box style={{width: '100%' }} />
                <CardBasic styleCardSx={{minWidth:'750px'}} title='Socio'>
                  {
                    [isFetching,isFetchingOne].some(Boolean) && (
                      <Box style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress style={{width: '28px', height: '28px'}}/>
                      </Box>
                    ) }
                  {
                    socios && !isFetching && !isFetchingOne && !err && <InitialArraySearch setShowLink={setShowLink} setDni={setDni} data={socios} setSocio={setSocio} setSocios={setSocios} isFetching={isFetching} err={err} />
                  }
                  {
                    socio && !isFetching &&  !err && (
                      <>
                        <Box>
                          <TableMinimal data={dataArr} />
                        </Box>
         
                      </>
                    ) 
                  }
                  {
                    err && !isFetching && (
                      <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                        No existen resultados.
                      </Box>
                    )
                  }
                </CardBasic>
                <AsideMenuActions 
                  socio={socio}
                  isFetching={isFetching}
                  refetchCobros={refetchCobros}
                  err={err}
                  permis={permis}
                  handleDispositivo={handleDispositivo}
                  handleRedirect={handleRedirect}
                  refetchAuth={refetchAuth}
                  dni={dni}
                  showLink={showLink}
                  handleLink={handleLink}
                  setOpenAM={setOpenAM}
                />
              </Box>
            </Box>
          </>
        )
        }
      </Box>
      <ModalCustom 
        textAction={'Guardar'} 
        title="Apto medico" 
        handleClose={handleClose} 
        open={openAM} 
        setOpen={setOpenAM} 
        showButtons 
        onClickAction={handleAptoMedico}
        disabledAction={loadingApto}
      >
        <Box style={{display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '1000px', justifyContent: 'center', alignItems: 'center'}}>
          <TextField 
            type="date" 
            label="Fecha de vigencia" 
            InputLabelProps={{ shrink: true}} 
            variant={'outlined'}
            defaultValue={moment(socio?.apto_medico?.fecha_vigencia).format('YYYY-MM-DD')}
            onChange={(e) => setAptoFecha(moment(e.target.value).format('YYYY-MM-DD'))}/>

          <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '10px', padding: '10px'}}>
            <ButtonAws
              setUrl={handleApto} 
              text={'Subir Imagen'}
              accept='.pdf, .jpeg, .jpg'
            />
          </Box>
          {
            socio?.apto_medico?.url && (
              <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  padding: '10px'}}>
                <Button
                  variant={'outlined'}
                  color='primary' 
                >
                  <a style={{outline: 'none', textDecoration: 'none', color: theme.palette.primary.main}} target="_blank" href={socio?.apto_medico?.url} rel="noreferrer"> Ver Apto
                  </a>
                </Button>
              </Box>
            )
          }
        </Box>
      </ModalCustom>
    </>
  );
};

export default Busqueda;

