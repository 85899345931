import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Radio, RadioGroup, Switch, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BooleanInput, CheckboxGroupInput, minValue, required, useDataProvider, usePermissions, useRecordContext } from 'react-admin';
import SectionForm from '../../../Components/Form/SectionForm';
import { useForm, useWatch } from 'react-hook-form';
import { createNotification, getMerchantId, getToken } from '../../helpers/helpFunctions';
import { useQuery } from 'react-query';
import { makeStyles } from '@mui/styles';
import { brandSportClub, merchantCentral, merchantContext, merchantsInToken } from '../../../DataProvider';
import { WarningComponent } from '../../../Components/CustomFields/WarningComponent';
import { toBeEnabled } from '@testing-library/jest-dom/matchers';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
});

const choicesLevelMN = [
  { id: 'Plus', name: 'Plus' },
  { id: 'Total', name: 'Total'},
  { id: 'Elite', name: 'Elite'},
  { id: 'Full', name: 'Full'},
  { id: 'Digital', name: 'Digital'},
  { id: 'SocioFlex', name: 'SocioFlex'},
  { id: 'FlexCorporativo', name: 'FlexCorporativo'},
  { id: 'Workclub', name: 'Workclub'},
];

const choicesLevel = [
  { id: 'Local', name: 'Local'},
];

const selectCobro = [
  { id: 'Mensual', name: 'Mensual'},
  { id: 'Trimestral', name: 'Trimestral'},
  { id: 'Cuatrimestral', name: 'Cuatrimestral'},
  { id: 'Semestral', name: 'Semestral'},
  { id: 'Anual', name: 'Anual'},
];

//Logica para tarjeta naranja merchant ELIMINAR proximamente

const hasCredentialsSelected = (merch) => {
  if([merch?.credentials?.mp_marketplace?.access_token,
    merch?.credentials?.mp_marketplace?.public_key,
    (merch?.credentials?.decidir?.site_id && merch?.credentials?.decidir?.site_id !== '')].some(Boolean)) {
    return true;
  } else {
    return false;
  }
};

const planEdit = (isMN, isCentral, isMP, merchant) => {
  const { getMerchant } = useContext(merchantContext);
  const hasCred = (hasCredentialsSelected(merchant) || hasCredentialsSelected(getMerchant())) ? true : false;
  const record = useRecordContext();
  const [levelChoice, setLevelChoice] = useState(record ? record?.nivel_de_acceso : !isMN ? 'Local' : null);
  const [aceptaCuotas, setAceptaCuotas] = useState(record?.cobro ? record?.cobro !== 'Mensual' : false);
  const [payment, setPayment] = useState(record ? record?.payment_methods : {efectivo: true, tarjeta: hasCred, crypto: false});
  const [enableCoutas, setEnableCuotas] = useState(record ? (record?.['acepta-cuotas'] || record?.cuotas) : false);
  const [sede, setSede] = useState(record?.sede_local ? record?.sede_local : null);
  const [selectedCobro, setSelectedCobro] = useState(record?.cobro ? record?.cobro : '');
  const [price, setPrice] = useState(record?.price ? record?.price : null);
  const [sedes, setSedes] = useState([]);
  const [planesHerencia, setPlanesHerencia] = useState(null);
  const [applicationFee, setApplicationFee] = useState(record?.application_fee ? record.application_fee : null);
  const permis = usePermissions().permissions;
  const dataProvider = useDataProvider();
  const filterPlan = {'merchant_id': record?.merchant_id, 'cobro': {'$ne': 'Anual'}};
  
  // PERMISOS POR ROL para edicion
  const superOwnerPermis = (permis === 'superadmin' || permis === 'owner');
  const adminCentral = permis === 'admin' && getToken()?.negocio_central;

  // Permisos y logica para edit DE PLANES LEGACY PARA CENTRALES
  const isCentralAuth = (superOwnerPermis || adminCentral) && isCentral;
  const legacyEditCentral = record && !isMN && record?.nivel_de_acceso !== 'Local' && isCentral;

  /* PERMISO PARA EDIT  DE APPLICATION FEE PARA PLANES LOCALES CON MERCHANTS HABILITADOS
  *
  * Importante aclarar que la variable isMP se encarga de verificar si el merchant tiene credenciales de mp_marketplace activadas, 
  * y por ende esta habilitado para venta de planes cadena y split de pago.
  */

  //PERMISOS PLANES LEGACY NO CENTRALES
  const legacyEditNoCentral = record && record?.['tipo-de-plan'] !== 'local' && record?.nivel_de_acceso !== 'Local' && !isCentral;

  // Lógica para setear correctamente los valores de payment en caso de que un owner o admin con varios merchant cambie los negocios
  // Esta lógica hay que eliminarla e introducir procesador de pago en efectivo
  useEffect(() => {
    setPayment(record ? record?.payment_methods : {efectivo: true, tarjeta: hasCred, crypto: false});
    return () => {
      setPayment(record ? record?.payment_methods : {efectivo: true, tarjeta: hasCred, crypto: false});
    };

  },[merchant, getMerchant()?.id]);

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {}
  };

  const paramsHerencia = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: filterPlan
  };

  const {isLoading } = useQuery(
    ['sedes'],
    () => dataProvider.getList('sedes', params),
    {
      onSuccess: (data) => setSedes(data.data)
    }
  );

  useQuery(
    ['planes'],
    () => dataProvider.getList('planes', paramsHerencia),
    {
      onSuccess: (data) => setPlanesHerencia(data.data)
    }
  );

  const handleMethod = (e, method) => {
    let value = e.target.checked;
    let obj = {...payment, [method]: value};
    console.log(value, obj);
    return setPayment(obj);
  };

  const handleCobroCuotas = (e) => {
    setAceptaCuotas(e.target.value !== 'Mensual');
    setEnableCuotas(e.target.value !== 'Mensual');
    setSelectedCobro(e.target.value);
  };

  const cuotasMemo = useMemo(() => {
    switch(!!selectedCobro){
    case selectedCobro === 'Trimestral':
      return [
        {key: 0, name: 'Todas las cuotas'},
        {key: 1, name: 'Solo 1 cuota'},
        {key: 3, name: 'Solo 3 cuotas'}
      ];
    case selectedCobro === 'Cuatrimestral':
      return [
        {key: 0, name: 'Todas las cuotas'},
        {key: 1, name: 'Solo 1 cuota'},
        {key: 3, name: 'Solo 3 cuotas'}
      ];
    case selectedCobro === 'Semestral':
      return [
        {key: 0, name: 'Todas las cuotas'},
        {key: 1, name: 'Solo 1 cuota'},
        {key: 3, name: 'Solo 3 cuotas'},
        {key: 6, name: 'Solo 6 cuotas'},
      ];
    case selectedCobro === 'Anual':
      return [
        {key: 0, name: 'Todas las cuotas'},
        {key: 1, name: 'Solo 1 cuota'},
        {key: 3, name: 'Solo 3 cuotas'},
        {key: 6, name: 'Solo 6 cuotas'},
        {key: 12, name: 'Solo 12 cuotas'},
      ];
    }
  },[selectedCobro]);

  const preferedMethods = useMemo(() => {
    let permisEnabled = ['superadmin', 'owner'].includes(permis);
    switch(true){
    // case permisEnabled && selectedCobro === 'Anual' && merchant?.id === '6564b7a3481c8ecadde29720': 
    //   return [
    //     {
    //       checked: payment?.efectivo,
    //       disabled:legacyEditNoCentral && !superOwnerPermis,
    //       onChange: (e) => handleMethod(e, 'efectivo'),
    //       label: 'Efectivo'
    //     },
    //     {
    //       checked: payment?.tarjeta,
    //       disabled:legacyEditNoCentral && !superOwnerPermis,
    //       onChange: (e) => handleMethod(e, 'tarjeta'),
    //       label: 'Tarjeta'
    //     },
    //     {
    //       checked: payment?.crypto,
    //       disabled:legacyEditNoCentral && !superOwnerPermis,
    //       onChange: (e) => handleMethod(e, 'crypto'),
    //       label: 'Crypto'
    //     }
    //   ];
    default: 
      return  [{
        checked: payment?.efectivo,
        disabled:legacyEditNoCentral && !superOwnerPermis,
        onChange: (e) => handleMethod(e, 'efectivo'),
        label: 'Efectivo'
      },
      {
        checked: payment?.tarjeta,
        disabled:legacyEditNoCentral && !superOwnerPermis,
        onChange: (e) => handleMethod(e, 'tarjeta'),
        label: 'Tarjeta'
      }];
    }
  },[selectedCobro, merchant, payment]);
  // console.log(payment);
  const className = useStyles();
  const data = [
    {
      source: 'nivel_de_acceso',
      label: 'Nivel de Acceso',
      type: 'select',
      onChange: (item)=> setLevelChoice(item.target.value),
      // initialValue: record && [...choicesLevel, ...choicesLevelMN]?.find(item => item?.name === record?.nivel_de_acceso && item),
      choices: 
        (!record && isMN) ? choicesLevelMN :
          !record && isCentralAuth && !isMN ? [{id: 'Local', name: 'Local'}, ...choicesLevelMN] :
            !record && !isCentralAuth && !isMN ? choicesLevel :
              record?.nivel_de_acceso && isMN ? choicesLevelMN :
                record?.nivel_de_acceso !== 'Local' && !isMN ? choicesLevelMN : choicesLevel,
      defaultValue:(!record?.nivel_de_acceso && (!isMN || !isCentralAuth)) ? { id: 'Local', name: 'Local'} : record?.nivel_de_acceso ? [...choicesLevel, ...choicesLevelMN]?.find(item => item?.id === record?.nivel_de_acceso && item) : null,
      validate: record?.nivel_de_acceso || !levelChoice && {
        required: 'Falta el campo Nivel de acceso en Plan'
      },
      disabled: legacyEditNoCentral && !superOwnerPermis,
      optionLabel: 'name',
      optionValue: 'id',
      tooltip: 'Tipo de plan y lo que este habilita',
      xs: record?.nivel_de_acceso === 'Local' && (superOwnerPermis && !isCentral) ? 6 : 12
    },
    (getToken()?.brand_id !== '6328afb359d052e6817c2200') ? null :
      (!isMN && sedes.length > 0 && (!isCentral || isCentral && levelChoice === 'Local')) ? 
        {
          source: 'sede_local',
          type: 'autocomplete',
          choices: sedes,
          label: 'Sede',
          perPage: 70,
          onChange: (e,v) => setSede(e),
          // value: sede,
          erase: (legacyEditNoCentral && !superOwnerPermis) || (isCentralAuth && (levelChoice !== 'Local' && levelChoice)),
          disabled: (legacyEditNoCentral && !superOwnerPermis) || (isCentralAuth && (levelChoice !== 'Local' && levelChoice)),
          validate: levelChoice === 'Local' && {
            required: 'Falta el campo Sede en Plan',
            validate: {
              isEmpty: (e, v) => typeof sede !== 'string' ? 'Es necesario seleccionar una sede de las opciones disponibles.' : undefined
            }
          
          },
          xs: !isCentralAuth ? 6 : 12
        } : (isLoading) ? {
          type: 'skeleton',
          xs: !isCentralAuth ? 6 : 12
        } : (!isMN && !isCentral) ? {
          source: 'sede_local',
          type: 'autocomplete',
          label: 'Sede',
          choices: sedes,
          validate: {
            required: 'Es necesario crear una sede para poder vender planes locales, y vincularla al negocio.'
          },
          xs: !isCentralAuth ? 6 : 12
        } : null,
    {
      source: 'price',
      label: 'Precio',
      onChange: (e) => setPrice(e.target.value),
      value: price,
      toNumber: true,
      validate: {
        pattern: {
          value: /^[0-9/ -()]+$/,
          message: 'El precio debe conener solo números.'
        },
        validate: {
          isRequired: () => !price ?  'Falta el campo Precio en Plan' : undefined
        },
        min: {
          value: Number(process.env.REACT_APP_MIN_PRICE),
          message: `El valor debe ser superior o igual que $ ${process.env.REACT_APP_MIN_PRICE}`
        },
      },
      tooltip: 'Precio del plan que figura en la landing page',
      xs: 6
    },
    {
      source: 'precio_compare_2',
      label: 'Precio Anterior',
      type: 'number',
      format: (d) => {
        if(d){
          if(typeof d !== 'number'){
            return parseInt(d.slice(2,999).replace('.', ''));
          }else{
            return d;
          }
        }
      },
      xs: 6
    },
    {
      source: 'cobro',
      type: 'select',
      label: 'Cobro',
      choices: selectCobro,
      onChange: (e) => handleCobroCuotas(e),
      defaultValue: selectCobro.find(item => record?.cobro === item.id),
      optionLabel: 'name',
      disabled: record?.cobro || (legacyEditNoCentral && !superOwnerPermis),
      validate: {required: 'Falta el campo Cobro en Plan'},
      xs: 6
    },
    
    {
      label: 'Plan Herencia',
      type: 'autocomplete',
      choices: planesHerencia,
      reference: isMN ? 'multinegocio' : 'planes',
      perPage: 999,
      source: 'plan_herencia',
      optionLabel: 'name',
      disabled: !aceptaCuotas || (legacyEditNoCentral && !superOwnerPermis),
      selectedValue: 'id',
      xs: 6
    },
    (selectedCobro && selectedCobro !== 'Mensual') && {
      source: 'cuotas',
      type: 'select',
      label: 'Número de cuotas',
      choices: cuotasMemo,
      optionLabel: 'name',
      validate: {
        required: 'Este campo es requerido.'
      },
      optionValue: 'key',
      xs: 6
    },
    {
      source: 'payment_methods',
      title: 'Método de pago',
      type: 'checkbox',
      // initialValue: payment,
      value: payment,
      disabled: !hasCred || (legacyEditNoCentral && !superOwnerPermis),
      xs: record ? 12 : 6,
      validate: {
        validate: {
          isReq: () => !payment?.efectivo && !payment?.tarjeta && !payment?.crypto ? 'Es necesario seleccionar al menos 1 método de pago.' : undefined
        }
      },
      options: preferedMethods
    },
 
    (superOwnerPermis && !isMN) && {
      custom: true,
      title: 'Comisión Cadena',
      component: 
        <>
          <FormControl>
            <RadioGroup
              name="radio-buttons-group"
            >
              <FormControlLabel
                disabled={!isMP}
                control={<Checkbox checked={(applicationFee === '' || applicationFee === null)} onClick={() => (applicationFee === '0' || Number(applicationFee) > 0) ? setApplicationFee('') : setApplicationFee('0')} /> }
                label={<Typography style={{fontSize: '14px'}}
                >Default del Negocio <br />{record?.cobro ? ` ($ ${merchant?.application_fee?.[record.cobro.toLowerCase()] ?? '0'})` : selectedCobro ? ` ($ ${merchant?.application_fee?.[selectedCobro?.toLowerCase()] ?? '0'})` : ''}</Typography>} />
              {(price && !applicationFee && (record?.application_fee && (merchant?.application_fee?.[record?.cobro?.toLowerCase()] >= record?.price))) ||
               ( price && !applicationFee && !record?.application_fee && (merchant?.application_fee?.[selectedCobro?.toLowerCase()] >= Number(price))) ? (
                  <WarningComponent style={{width: '100%', minWidth: '400px'}}> La totalidad del cobro de este plan se asignará a cadena.</WarningComponent>
                ) : (price && (applicationFee >= Number(price))) ? (
                  <WarningComponent style={{width: '100%', minWidth: '400px'}}> La totalidad del cobro de este plan se asignará a cadena.</WarningComponent>
                ) : (record?.price && (applicationFee >= record?.price)) ? (
                  <WarningComponent style={{width: '100%', minWidth: '400px'}}> La totalidad del cobro de este plan se asignará a cadena.</WarningComponent>
                )
                  : null}
            </RadioGroup>
          </FormControl>
        </>,
      xs: (record || !(superOwnerPermis && !isCentral)) ? 6 : 3,
    },
    (superOwnerPermis && !isMN) && {
      source: 'application_fee',
      type: 'number',
      label: 'Particular del plan',
      toNumber: true,
      titleSpace: true,
      value: applicationFee,
      placement: 'top',
      erase: !isMP || isCentral,
      // placeholderText: (applicationFee > Number(price) || applicationFee > record?.price) ? 'La totalidad del cobro de este plan se asignará a cadena.' : null,
      tooltip: !isMP ? 'No se puede aplicar comisión al plan si el negocio no esta habilitado para la venta de planes cadena' : '',
      disabled: !isMP,
      onChange: (e) => setApplicationFee(e.target.value),
      className: className.input, 
      xs: (record || !(superOwnerPermis && !isCentral)) ? 6 : 3,
    },
  ];
  return data;

};

export default planEdit;