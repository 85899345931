import { Button, Input, Link } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { DateInput, required, useDataProvider, useRecordContext } from 'react-admin';
import SectionForm from '../../../Components/Form/SectionForm';
import { choicesProvinciasExpand } from '../../helpers/choicesSelects';
import { createNotification } from '../../helpers/helpFunctions';
import { CheckInComponent } from './CheckInComponent';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Stack, TextField, Typography, Box } from '@mui/material';
import useCheckField from '../../../Hooks/useCheckField';
import { MonthPicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useForm } from 'react-final-form';
import { AiOutlineWarning } from 'react-icons/ai';

const defaultDate = new Date(3000, 1, 1);
const today = new Date();

const afipMerchant = () => {
  const record = useRecordContext();
  const isEdit = record ? true : false;
  const [afip, setAfip] = useState(record?.afip ? record.afip : {cuit: null, razon_social: null});
  const [activeWarning, setActiveWarning] = useState(false);
  const [checkBool, setCheckBool] = useState(record?.afip?.facturar_cash ? record.afip.facturar_cash : false);
  const [showCheck, setShowCheck] = useState(record?.afip?.activate_facturacion ? record.afip.activate_facturacion : false);
  const [dateCheck, setDateCheck] = useState(record?.afip?.date_init_facturacion ? record?.afip?.date_init_facturacion : defaultDate);
  const [cashDate, setCashDate] = useState(record?.afip?.date_init_cash ? record.afip.date_init_cash : defaultDate);
  const [split, setSplit] = useState(null);
  const ref = useRef(false);

  const checkCuit = useCheckField('merchants', 'afip.cuit', afip.cuit);

  const handlerCuit = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, '');
    setAfip({...afip, cuit: value});
    // form.change('afip.cuit', value);
  };

  const handlerVenta = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, '');
    setAfip({...afip, pto_venta: value});
  };
  
  const handleRS = (e) => {
    setAfip({...afip, razon_social: e.target.value});
  };

  const handleIibb = (e) => {
    setAfip({...afip, ii_bb: e.target.value});
  };

  const handleDate = (e) => {
    setAfip({...afip, inicio_de_actividades: moment(e).format('L')});
  };

  const handleProvincia = (e) => {
    setAfip({...afip, 
      domicilio: ({
        ...afip.domicilio,
        provincia: e.target.value
      })
    });
  };

  const handleLocalidad = (e) => {
    setAfip({...afip, 
      domicilio: ({
        ...afip.domicilio,
        localidad: e.target.value
      })
    });
  };

  const handleCalle = (e) => {
    setAfip({...afip, 
      domicilio: ({
        ...afip.domicilio,
        calle: e.target.value
      })
    });
  };

  const handleAltura = (e) => {
    setAfip({...afip, 
      domicilio: ({
        ...afip.domicilio,
        altura: e.target.value
      })
    });
  };

  const handleCodigo = (e) => {
    setAfip({...afip, 
      domicilio: ({
        ...afip.domicilio,
        ['código postal']: e.target.value
      })
    });
  };

  // 2023-07-06T20:39:46.979Z

  useEffect(() => {
    if(ref.current === false){
      ref.current = true;
      return;
    }else {
      if(showCheck) {
        setDateCheck(today);
      } else  {
        setDateCheck(defaultDate);
        setCashDate(defaultDate);
        setCheckBool(false);
      }
    }

  },[showCheck]);

  useEffect(() => {
    if(checkBool) {
      setCashDate(today);
    } else {
      setCashDate(defaultDate);
    }
  },[checkBool]);
  
  const fieldValidation = () => {
    const fields = [
      afip?.cuit?.length === 11,
      afip?.razon_social,
      afip?.pto_venta,
      afip?.ii_bb,
      afip?.inicio_de_actividades,
      afip?.domicilio?.provincia,
      afip?.domicilio?.localidad,
      afip?.domicilio?.calle,
      afip?.domicilio?.altura,
      afip?.domicilio?.['código postal']
    ];
    return fields.every(Boolean);
  };

  useEffect(() => {
    setActiveWarning(fieldValidation());
  },[afip]);


  const classes = {
    'text-decoration': 'underline'
  };

  const WarningFacturante = () => (
    <Box style={{backgroundColor: '#fff59d', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', marginBottom: '10px', width: '100%', padding: '15px', display: `${activeWarning ? 'flex' : 'none'}`, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
      <AiOutlineWarning size={40} />
      <Typography  style={{marginLeft: '10px', fontSize: '14px', fontWeight: '400', width: '100%'}}>
    Antes de Habilitar la facturacion automática, es necesario excluir el concepto “Suscripción SportClub” en la pagina de Facturante, siguiendo este <a style={classes} rel="noreferrer" target={'_blank'} href={'https://facturante.ladesk.com/371812-Facturante---Mercado-Pago-C%C3%B3mo-excluir-el-costo-de-env%C3%ADo-en-el-Comprobante'}>instructivo.</a><br/>No realizar esta exclusión puede resultar en una duplicación de facturas. El concepto debe ser copiado tal cual, caso contrario no se excluirán estas facturas del proceso de Facturante.<br /><br/>
    Enviar un email a ignacio@muvinai.com para continuar con la activación del proceso de facturación.
      </Typography>
    </Box>
  );
  const data = [

    {
      custom: true,
      component: <div style={{borderBottom: '1px solid #cfd8dc'}}>
        <Typography style={{color: '#9ea7aa'}}>Información Fiscal</Typography>
      </div>,
      xs: 12
    },
    {
      label: 'CUIT *',
      source: 'afip.cuit',
      xs: 12,
      initialValue: afip?.cuit,
      // onBlur: handlerCuitData,
      // checkOnblur: checkCuit,
      onChange: handlerCuit,
      validate: {
        maxLength: {
          value: 11,
          message: 'El cuit debe tener 11 caracteres sin espacios o guiones'
        },
        minLength: {
          value: 11,
          message: 'El cuit debe tener 11 caracteres sin espacios o guiones'
        },
        pattern: {
          value: /^[0-9/ -()]+$/,
          message: 'No puede contener letras'
        }
      },
      value: afip.cuit,
    },
    {source: 'afip.razon_social', label: 'Razón social *', defaultValue: afip?.razon_social, onChange: handleRS , xs: 6},
    {source: 'afip.pto_venta', label: 'Punto de venta *', validate: {
      pattern: {
        value: /^[0-9/ -()]+$/,
        message: 'No puede contener letras'
      }
    }, defaultValue: afip?.pto_venta, onChange: handlerVenta,   xs: 6}, // type number / float
    {source: 'afip.ii_bb', label: 'Ingresos Brutos *', defaultValue: afip?.ii_bb, onChange: handleIibb,  xs: 6},
    {
      custom: true,
      component:
      <LocalizationProvider dateAdapter={AdapterMoment}>
        
        <DesktopDatePicker 
          value={afip.inicio_de_actividades ? afip.inicio_de_actividades : null}
          inputFormat="DD/MM/yyyy"
          label="Inicio de Actividades" 
          onChange={handleDate}
          renderInput={(params) => 
            <TextField
              fullWidth
              variant="outlined"
              {...params} 
            />}
        />
      </LocalizationProvider>,
      xs: 6
    },
    // {
    //   source: 'afip.inicio_de_actividades',
    //   type: 'date',
    //   label: "Inicio de Actividades *" ,
    //   onChange: handleDate,
    //   xs: 6
    // },
    {
      custom: true,
      component: <div style={{borderBottom: '1px solid #cfd8dc'}}>
        <Typography style={{color: '#9ea7aa',fontWeight: 'normal'}}>Domicilio</Typography>
      </div>,
      xs: 12
    },
    {
      source: 'afip.domicilio.provincia', 
      label: 'Provincia *',
      type: 'select',
      onChange: handleProvincia,
      defaultValue: afip?.domicilio?.provincia,
      choices: choicesProvinciasExpand,
      optionLabel: 'name',
      optionValue: 'id', 
      xs: 12
    },
    {source: 'afip.domicilio.localidad', defaultValue: afip?.domicilio?.localidad, label: 'Localidad *', onChange: handleLocalidad,  xs: 12},
    {source: 'afip.domicilio.calle', defaultValue: afip?.domicilio?.calle, label: 'Calle *', onChange: handleCalle, xs: 3},
    {source: 'afip.domicilio.altura', label: 'Altura *', defaultValue: afip?.domicilio?.altura, onChange: handleAltura, validate: {
      pattern: {
        value: /^[0-9/ -()]+$/,
        message: 'No puede contener letras'
      }
    }, xs: 3},
    {source: 'afip.domicilio.código postal', label: 'Código Postal *', defaultValue: afip?.domicilio?.['código postal'], onChange: handleCodigo, xs: 3},
    {source: 'afip.domicilio.apto_lote', label: 'Apto Lote *', defaultValue: afip?.domicilio?.apto_lote, xs: 3},
   
    {
      source: 'afip.activate_facturacion',
      displayNone: true,
      value: showCheck,
      
    },
    {
      source: 'afip.facturar_cobros',
      displayNone: true,
      value: afip.facturar_cobros,
    },
    {
      source: 'afip.date_init_cobros',
      displayNone: true,
      value: afip.date_init_cobros,
    },
    {
      source: 'afip.date_init_facturacion',
      value: dateCheck,
      displayNone: true,
    },
    {
      source: 'afip.date_init_cash',
      value: cashDate,
      displayNone: true,
    },
    {
      source: 'afip.facturar_cash',
      displayNone: true,
      value: checkBool,
    },
    {
      source: 'afip.inicio_de_actividades',
      value: afip.inicio_de_actividades,
      displayNone: true
    },
    {
      source: 'afip.facturacion_split',
      value: afip.facturacion_split,
      displayNone: true
    },
    {
      source: 'afip.tipo_facturacion',
      value: afip.tipo_facturacion,
      displayNone: true
    },
    {
      source: 'afip.acepta_factura_a',
      value: afip.acepta_factura_a,
      displayNone: true
    },
    {
      custom: true,
      component: <Box style={{margin: '10px 0', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #cfd8dc'}}>
        <Typography  style={{ color: '#9ea7aa',fontWeight: 'normal', display: 'flex'}}>Facturación</Typography>
        <Link 
          target="_blank"
          rel='noopener' 
          underline='none' 
          style={{
            textTransform: 'uppercase',
            fontSize: '0.8em', 
            fontWeight: '400', 
            outline: 'none', 
            border: 'none'
          }} 
          href='https://docs.google.com/document/d/1Hu2ijKhYlOVObT5Q0FYhih5mdywhRVRpX-Wqo-bOL8A/edit#heading=h.1upm6vjtowqt'>Instructivo</Link>
      </Box>,
      xs: 12
    },
    {
      custom: true,
      component: <WarningFacturante />
    },
    {
      custom: true,
      component: <CheckInComponent
        showCheck={showCheck} 
        setShowCheck={setShowCheck}
        checkBool={checkBool} 
        setCheckBool={setCheckBool} 
        dateCheck={dateCheck} 
        setDateCheck={setDateCheck} 
        setAfip={setAfip}
        afip={afip} 
        record={record}
        setSplit={setSplit}
        split={split}
      />,
      xs: 12,
    },
  ];

  return data;
};

export default afipMerchant;