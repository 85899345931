import React, { useEffect, useState } from 'react';
import ButtonAws from '../../../Components/CustomFields/ButtonAws';
import { Box, Button } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { ModalApto } from '../../../Components/UI/ModalCustom';
import moment from 'moment';


const handleInitialDate = () => {
  const date = new Date();
  const newDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()).toISOString().slice(0,10);
  return newDate;
};

const aptoMedicoSocio = () => {
  const record = useRecordContext();
  const [urlMedico, setUrlMedico] = useState(record?.apto_medico?.url ? record.apto_medico.url : '');
  const [medico, setMedico] = useState(record?.apto_medico?.fecha_vigencia ? new Date(record.apto_medico.fecha_vigencia).toISOString().slice(0,10) : handleInitialDate());


  const handlerDelete = () => {
    setUrlMedico('');
    // setMedico(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  };

  const handleApto = (url) => {
    setUrlMedico(url);
  };

  const validateDate = (e) => {
    if (!moment(medico, 'YYYY-MM-DD', true).isValid()) {
      return 'Fecha no válida';
    }
    if (record?.apto_medico && moment(medico).isBefore(moment(handleInitialDate(), 'YYYY-MM-DD'))) {
      return 'No puede ser menor al mes de gracia inicialmente otorgado';
    }
    return undefined;
  };
  
  const data = [
    {
      custom: true,
      component: <ButtonAws
        setUrl={handleApto} 
        text={(urlMedico) ? 'Actualizar Apto Medico' : 'Subir Apto Medico'}
        accept='.pdf, .jpeg, .jpg'
      />,
      xs: 12
    },
    {
      type: 'date',
      source: 'apto_medico.fecha_vigencia',
      onChange: (e) => setMedico(moment(e.target.value).utc().format('YYYY-MM-DD')),
      defaultValue: medico,
      value: medico,
      validate: {
        required: 'Completar campo con una fecha correcta', 
        validate: {
          isGreaterThan: validateDate
        }
        // validate: {validateDate}
      },
      
      label: 'Vigencia',
      xs: 6
    },
    urlMedico && {
      component: <ModalApto text='Ver Apto Medico' url={urlMedico} />,
      custom: true,
      xs: 6
    },
    urlMedico && {
      component:  <Button variant='outlined' color='error' onClick={handlerDelete}>Eliminar Apto Medico</Button>,
      custom: true,
      xs: 6
    },
    {
      displayNone: true,
      label: 'Eliminar Apto Médico',
      source: 'apto_medico.url',
      value: urlMedico,
      xs:12
    }
  ];

  return data;
};


export default aptoMedicoSocio;