import React, { useState } from 'react';
import { Button, Box} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useDataProvider, useRedirect,useRefresh } from 'react-admin';
import { createNotification } from '../../Resources/helpers/helpFunctions';
import { ButtonToolbar } from './Buttons/ButtonToolbar';
import axios from 'axios';


const ButtonAddAccess = ({data, setSendData, refresh, redirect, setSocio}) => {
  // const refresh = useRefresh()
  // const redirect = useRedirect();
  // const refresh = useRefresh();
  const dataProvider = useDataProvider();
  
  const urlApi = process.env.REACT_APP_CREDENCIAL; 
  const hasData = () => {
    if(data){
      return data.manual?.manual_access_reason?.length > 0;
    }
    return false;
  };

  const postAccess = async () => {
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;

    if(data?.manual?.manual_access_reason){
      axios({
        url: `${urlApi}/cd/registro_acceso`,
        method: 'POST',
        data: data,
        headers: {
          'X-Access-Token': tokenSend
        }
      }).then(res => {
        refresh();
        setSocio(null);
        createNotification('success', 'Acceso manual agregado correctamente');
        redirect('create', 'accesos');
      }).catch(err => {
        createNotification('error', 'Error al crear el acceso manual. Codigo de Error: MUV-01', );
        throw new Error(err);
      });
    }
  };


  return (
    <Box sx={{
      position: 'sticky',
      width: 'calc(25% - 10px)',
      minWidth: '230px',
      height: '100%',
      marginTop: '110px',
      top: 0,
      padding: '60px 0'
    }}>
      <ButtonToolbar startIcon={<AddOutlinedIcon/>} disabled={!hasData()} onClick={postAccess}>
        Agregar Acceso
      </ButtonToolbar>
    </Box>
  );
};

export default ButtonAddAccess;