import React, { useContext, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { DeleteWithConfirmButton, RecordContextProvider, Show, useDataProvider, usePermissions, useRecordContext, useRefresh, useResourceContext } from 'react-admin';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import SedesHeader from './SectionsShow/SedesHeader';
import UbicationCard from './SectionsShow/UbicationCard';
import AmntsServsCard from './SectionsShow/AmntsServsCard';
import DescriptionCard from './SectionsShow/DescriptionCard';
import PlansCard from './SectionsShow/PlansCard';
import AccessCard from './SectionsShow/AccessCard';
import ImagesCard from './SectionsShow/ImagesCard';
import LevelAccessCard from './SectionsShow/LevelAccessCard';
import DeleteIcon from '@mui/icons-material/Delete';
import { bannerStyle } from '../../Theme/useStyle';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';
import VersionCard from '../../Components/UI/Show/VersionCard';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import EditIcon from '@mui/icons-material/Edit';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import { useQuery } from 'react-query';
import { createNotification } from '../helpers/helpFunctions';
import { CircularProgress } from '@mui/material';
import HorariosCard from './SectionsShow/HorariosCard';
import { ModalDelete } from '../../Components/UI/Toolbar/CustomBajaSocio';
import { theme } from '../../Theme/theme';
import ContactoCard from './SectionsShow/ContactoCard';
import { apiUrl, merchantContext } from '../../DataProvider';
import axios from 'axios';
import { ButtonToolbar } from '../../Components/UI/Buttons/ButtonToolbar';



const SedesShow = (props) => {
  const refresh = useRefresh();
  
  return (
    <Show 
      {...props} 
      title={<TitleEdit title='Sede: ' source='name'/>}
      aside={<AsideShow refresh={refresh} permis={props.permissions}/>} 
      actions={null}
    >
      <ShowTransparent>
        <Box style={bannerStyle}/>
        <SedesHeader/>
        <HorariosCard />
        <UbicationCard/>
        <AmntsServsCard/>
        <AccessCard/>
        <LevelAccessCard/>
        <DescriptionCard/>
        <ImagesCard/>
        <VersionCard/>
      </ShowTransparent>
    </Show>
  );
};

const AsideShow = ({refresh}) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const permis = usePermissions().permissions;
  const basePath = useResourceContext();
  const { getMerchant } = useContext(merchantContext);
  const resource = useResourceContext();
  const [loadingQr, setLoadingQr] = useState(false);

  const { isLoading, error, refetch } = useQuery(
    ['delete', {id: record?.id}],
    () =>  dataProvider.update('sedes', { data: { archivado: !record?.archivado}, id: record?.id}),
    {
      onSuccess: (res) => {
        createNotification('success', `Sede ${record?.archivado ? 'desarchivada' : 'archivada'} con éxito`);
        refresh();
      },
      enabled: false
    }
  );

  const download = async () => {
    setLoadingQr(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;

    axios({
      url: `${apiUrl}/qrcode/${record.id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*',
        'access-control-expose-headers' : '*'
      },
      responseType: 'blob'
    }).then(res => {
      setLoadingQr(false);

      const filename = res?.headers?.['content-disposition']?.split('filename=')[1];
      const url = window.URL
        .createObjectURL(new Blob([res?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename ? `${filename}` : `${record.name}.png`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((err) => {
      setLoadingQr(false);
      createNotification('error', 'Hubo un error');
    });
    
  };

  const aside = record ? [
    // permis !== 'operador' && 
    //   {
    //     text: 'Editar Sede',
    //     link: basePath + '/' + record?.id,
    //     icon: <EditIcon/>
    //   },
    {
      text: 'Ver Sede',
      href: `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/sedes/${record?.slug}`,
      icon : <RemoveRedEyeOutlinedIcon/>
    },
    (getMerchant()?.negocio_central || getMerchant()?.id === '') && {
      text: 'Enviar Mail',
      href: 'mailto:'+record['contact-email'],
      icon : <EmailOutlinedIcon />
    },
    {
      // text: 'Descargar QR',
      // icon : <QrCodeIcon/>, 
      custom: true,
      component: <ButtonToolbar 
        startIcon={loadingQr ? <CircularProgress size="20px" /> : <QrCodeIcon/>}
        onClick={download}
        disabled={loadingQr}
        style={{justifyContent: 'flex-start', padding: '9px 26px', fontWeight: '600', textTransform: 'none'}}
      >
        Descargar QR
      </ButtonToolbar>,
      // href: record?.qr_url
    },
    permis !== 'operador' && { divider: true },
    permis !== 'o?perador' && {
      text: record.archivado ? 'Desarchivar Sede' : 'Archivar Sede',
      icon:  isLoading ? <CircularProgress style={{width: '18px', height: '18px'}}/> :
        record?.archivado ? 
          <UnarchiveOutlinedIcon style={{fontSize: '16px', marginRight: '2px'}}/> :
          <ArchiveOutlinedIcon  style={{fontSize: '16px', marginRight: '2px'}}/>,
      onClick: () => refetch(),
      disabled: isLoading
    },
    
    permis !== 'operador' && { divider: true },
    permis !== 'operador' && {
      record: record,
      disabled: !record?.archivado,
      label: 'Eliminar Sede',
      confirmTitle: `¿Eliminar ${record?.name}?`,
      confirmContent: '¿Seguro quieres eliminar esta sede?',
      basePath: resource,
    }

  ] : [];

  return (
    <AsideActionsShow data={aside}/>
  );
};

export default SedesShow;