import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, CircularProgress, Stack, Typography, Button, TextField, Skeleton} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { createNotification, getListProvider, getToken } from '../helpers/helpFunctions';
import { fontStyle } from '../../Theme/useStyle';
import { FiAlertTriangle } from 'react-icons/fi';
import { BsCheckLg } from 'react-icons/bs';
import CardBasic from '../../Components/UI/Show/CardBasic';
import TableMinimal from '../../Components/UI/Show/TableMinimal';
import useAxios from '../../Hooks/useAxios';
import { apiUrl } from '../../DataProvider';
import axios from 'axios';
import { useQuery } from 'react-query';

const AccesoCreateEdit = ({isCreate, setSendData, sendData, socio, setSocio}) => {
  const [valueSede, setValueSede] = useState(null);
  const [valueDni, setValueDni] = useState('');
  const [sedes, setSedes] = useState([]);
  const [dataSocio, setDataSocio] = useState([]);
  const [loadingMerch, setLoadingMerch] = useState(null);
  const [loadingSedes, setLoadingSedes] = useState(false);
  const token = getToken();
  const firstRender = useRef(true);
  const dataProvider = useDataProvider();
  const refButton = useRef();
  const userEmail = getToken()?.email;
  
  const baseUrl = process.env.REACT_APP_CREDENCIAL; 
  
  
  const {data, isFetched, isLoading, isError, error, refetch} = useAxios({
    key: ['accesos_metrics', valueSede?.slug, valueDni],
    tokens: true,
    url:  `${baseUrl}cd/consulta_acceso?sede=${valueSede?.slug}&documento=${valueDni}`,
    // url: `${apiUrl}/validate_access?filter={"documento":"${valueDni}","sede":"${valueSede?.slug}"}`,
    params: {
      enabled: false, 
      onSuccess: (data) => {
        console.log(data);
        newSuccessSocio(data.data);
        setLoadingMerch(false);
      },
      onError: (e) => {
        setLoadingMerch(false);
        createNotification('error', e.message);
      }
    },
  });

  const url = `${baseUrl}cd/perfil_socio?documento=${valueDni}`;
  const { isFetched: isFetchedSocio, isFetching: isFetchingSocio, refetch: refetchSocio } = useQuery(
    ['perfil_socio'],
    async () =>{
      const token = localStorage.getItem('auth');
      const tokenSend = await JSON.parse(token)?.token;
      const response = await axios({
        url: url,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': 'origin, x-requested-with',
          'access-control-allow-origin': '*',
          'access-control-expose-headers' : '*',
          'X-Access-Token': tokenSend,
        }
      });
      return response.data;
    },
    {
      onSuccess: (data) => onSuccessSocio(data),
      onError: (err) => { 
        setSocio(null);
        setLoadingMerch(false);
        setDataSocio(null);
        createNotification('error', err.response.data.message);
        throw new Error(err);
      },
      enabled: false
    }
  );

  useEffect(() => {
    if(localStorage.getItem('documento')) {
      setValueDni(localStorage.getItem('documento'));
    }
    localStorage.removeItem('documento');
  },[]);
  

  useEffect(()=> {
    if(firstRender){
      setLoadingSedes(true);
      const params = {
        pagination: {page: 1, perPage: 999},
        sort: {field: 'name', order: 'DESC'},
        filter: {archivado: false}
      };
      dataProvider.getList('sedes', params).then(({data}) => {
        setLoadingSedes(false);
        setSedes(data);
        if(data.length === 1){
          setValueSede(data[0]);
        }
      }).catch(err => {
        setLoadingSedes(false);
        console.log(err);
      });
      const url = window.location.href.split('?dni=');
      url.length == 2 && setValueDni(url[1]);
    }
    return(() => {
      firstRender.current = false;
    });
  }, []);

  const newSuccessSocio = (e) => {
    // console.log(e)
    setSendData({...sendData, 'acceso': e});
    setSocio({...socio, 'acceso' : e});
  };

  const onSuccessSocio = (e) => {
    console.log(e);
    setDataSocio([
      {key: 'Nombre', value: e.nombre},
      {key: 'Apellido', value: e.apellido},
      {key: 'Categoria', value: e.plan_details.nivel_de_acceso},
      {key: 'Negocio', value: e.plan_details.merchant_name ?? e.sede_local_name ?? e.plan_details.sede_local_name ?? 'SportClub Central'}
    ]);
    setLoadingMerch(false);
      
    setSocio({...socio, 'cliente': e});
    setSendData({...sendData, 'cliente': e});
  };

  const getSocio = () => {
    if(valueDni?.length >= 7 && valueSede){
      setLoadingMerch(true);
      setSocio(null);
      setDataSocio(null);
      refetch();
      refetchSocio();
    }else{
      createNotification('warning', 'Por favor revisa los campos antes de continuar');
    }
  };

  const handlerDni = (e) => {
    setValueDni(e.target.value);
    if(socio){
      setSocio(null);
    }
  };
  
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <CardBasic styleBox={{display: 'flex', alignItems: 'center'}}>
        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField type='tel' label="Documento" variant="outlined" size='small'
            onChange={handlerDni} id='search_socio_dni' value={valueDni} style={{width: '130px',flexGrow: 1, marginRight: '5px'}}/>
          {loadingSedes ? (
            <Skeleton style={{width: '60%', height: '50px', marginRight: '5px'}} />
          ) : (
            <Autocomplete
              onChange={(event, newValue) => {
                setValueSede(newValue);
                newValue == null && setSocio(null);
              }}
              id='search_sede'
              value={valueSede}
              options={sedes}
              style={{ minWidth: '60%', alignSelf: 'flex-start', marginRight: '5px'}}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Sede'
                  variant='outlined'
                  size='small'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Box>
                        {params.InputProps.endAdornment}
                      </Box>
                    ),
                  }}
                />
              )}
            /> 
          )}
          <Button disabled={loadingSedes} style={{ display: 'flex', alignItems: 'center', padding: '7px', marginTop: '3px'}} variant='outlined' color='primary' ref={refButton} onClick={getSocio}>Buscar</Button>
        </Box>
      </CardBasic>
      <CardBasic title='Socio'>
        {isLoading || loadingMerch ? (
          <Box style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress style={{width: '28px', height: '28px'}}/>
          </Box>
        ) : !loadingMerch && socio && dataSocio && <TableMinimal data={dataSocio}/>}
      </CardBasic>
      {socio && dataSocio && (
        <CardBasic title='Acceso'>
          <Stack spacing={2}>
            {socio?.acceso?.status === 'autorizado' ? (
              <Box style={{display: 'flex', flexDirection: 'column'}}>
                <Box style={{display: 'flex'}}>
                  <BsCheckLg style={{fontSize: '20px', color: '#1d5c05', marginRight: '10px'}}/>
                  <Typography style={{...fontStyle, marginBottom: '10px', color: '#1d5c05', fontWeight: '600'}} >
                    {socio?.acceso?.message}
                  </Typography>
                </Box>
                <Typography style={{fontSize: '14px'}}>
                  Debes ingresar una aclaración para agregar el acceso de forma manual
                </Typography>
              </Box>
            ) : socio?.acceso?.status === 'advertencia' ? (
              <Box style={{display: 'flex', flexDirection: 'column'}}>
                <Box style={{display: 'flex'}}>
                  <FiAlertTriangle style={{fontSize: '22px', color: '#f6ca00', marginRight: '10px'}}/>
                  <Typography style={{...fontStyle, marginBottom: '10px', color: '#f6ca00', fontWeight: '600'}} >
                    {socio?.acceso?.message}
                  </Typography>
                </Box>
                <Typography style={{fontSize: '14px'}}>
                Puedes ingresar una aclaración para agregar el acceso de forma manual
                </Typography>
              </Box>
            ) : 
              <Box style={{display: 'flex', flexDirection: 'column'}}>
                <Box style={{display: 'flex'}}>
                  <FiAlertTriangle style={{fontSize: '22px', color: '#912a2a', marginRight: '10px'}}/>
                  <Typography style={{...fontStyle, marginBottom: '10px', color: '#912a2a', fontWeight: '600'}} >
                    {socio?.acceso?.message}
                  </Typography>
                </Box>
                <Typography style={{fontSize: '14px'}}>
                  Puedes ingresar una aclaración para agregar el acceso de forma manual
                </Typography>
              </Box>
            }
            <Box>
              <TextField label="Aclaración" variant="outlined" 
                size='small' required style={{width: '100%'}}
                onChange={e => setSendData({...sendData, 'manual':  {'user': userEmail, 'manual_access_reason': e.target.value}})}
                id='access_aclaracion'
              />
            </Box>
          </Stack>
        </CardBasic>
      )}
    </Box>
  );
};

export default AccesoCreateEdit;