import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const plansName = (plansIds, data) => {
  let planNames = '';
  data.map(e => {
    if(plansIds.includes(e.id)){
      planNames += `${e.name}, `;
    }
  });
  return planNames;
};

export const exporter = (corps) => {
  const token = localStorage.getItem('auth').slice(10,-2);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  fetch('https://backoffice.apisportclub.xyz/planes?filter={}&range=[0,999]&sort=["id","ASC"]',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': token
      }
    }
  )
    .then(response => response.json())
    .then(data => {
      const headerxls = [[
        'Nombre', 
        'Slug',
        'Email',
        'Categoria',
        'Direccion',
        'Provincia',
        'Zona',
        'Whatsapp',
        'Telefono',
        'SportAccess ID',
        'Horario L a V',
        'Horario Sabado',
        'Horario Domingos y Feriados',
        'Es del Interior',
        'Planes',
        'Descripcion',
        'Descripcion Corta',
      ]];
      const arrayxls = corps.map(e => ([
        e.name,
        e.slug,
        e['contact-email'],
        e.categoria,
        e.direccion,
        e.provincia,
        e.zona,
        e.whatsapp,
        e.telefono,
        e.sportaccess_id,
        e['horario-l-a-v'],
        e['horario-sabado'],
        e['horario-domingo-y-feriados'],
        e['es-del-interior'] ? 'Si' : 'No',
        plansName(e.planes, data.data),
        e.description,
        e['descripcion-corta'],
      ]));
      const totalArray = headerxls.concat(arrayxls);
      const workSheet = XLSX.utils.aoa_to_sheet(totalArray);
      const workBook = {
        Sheets: { data: workSheet, cols: [] },
        SheetNames: ['data'],
      };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const fileData = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(fileData, 'Sedes export' + fileExtension);
    });
};