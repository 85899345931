import React, { useContext, useEffect, useMemo, useState } from 'react';
import { choicesPlan } from '../../helpers/choicesSelects';
import SectionForm from '../../../Components/Form/SectionForm';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { hasAuth, roles } from '../../helpers/helpFunctions';
import { required, usePermissions, useRecordContext } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import { Checkbox, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { styleTabbleData, styleTableHead } from '../../../Theme/useStyle';
import { DispatchErrorContext } from '../../../Context/menuContext';


const levelAccess = () => {
  const permis = usePermissions()?.permissions;
  const record = useRecordContext();
  const [editLevel, setEditLevel] = useState(false);
  const [levelAccess, setLevelAccess] = useState({
    flexcorporativo: record?.nivel_de_acceso?.flexcorporativo  ?? null,
    full: record?.nivel_de_acceso?.full  ?? null,
    plus: record?.nivel_de_acceso?.plus  ?? null,
    total: record?.nivel_de_acceso?.total  ?? null,
    elite: record?.nivel_de_acceso?.elite  ?? null,
    local: record?.nivel_de_acceso?.local ?? 0,
    // digital: record?.nivel_de_acceso?.digital ?? null,
    socioflex: record?.nivel_de_acceso?.socioflex ?? null, 
    workclub: record?.nivel_de_acceso?.workclub ?? null, 
  });

  const setNullAccess = (obj) => {
    return Object.fromEntries(Object.values(obj)?.map((item, index) => {
      if(item !== 0 && (item === 'denegado' || !item)) return [Object.keys(obj)[index], null];
      return [Object.keys(obj)[index], parseFloat(item)];
    }));
  };

  const checkNumber = /^[0-9/ -()]+$/;

  const handlerLevels = (e) => {
    const val = e.target.value;
    const accessNew = {
      plus: val=='Plus'?'0':'denegado',
      total: (val=='Plus' || val=='Total') ? '0' : 'denegado',
      elite: '0',
    };
    setLevelAccess(setNullAccess({...levelAccess, ...accessNew}));
    // form.change('nivel_de_acceso', {...levelAccess, ...accessNew});
  };

  
  const customComponent = (
    <FormGroup >
      <FormControlLabel control={<Switch checked={editLevel} onClick={(e) => setEditLevel(e.target.checked)} disabled={!hasAuth(permis, roles.slice(0,2))}/>} label="Editar" />
    </FormGroup> 
  );

  const data = [
    {
      type:'select',
      choices: choicesPlan, 
      source: 'categoria',
      label: 'Categoria',
      optionLabel: 'name',
      onChange: handlerLevels,
      validate: {required: 'Falta el campo Categoria en Nivel de Acceso'},
      xs: 12
    },
    {
      source: 'nivel_de_acceso',
      value: levelAccess,
      displayNone: true,
    },
    {
      custom: true, 
      component: customComponent,
      xs: 6
    },
    {
      custom: true,
      component: <>
        <TableContainer component={Paper} style={{boxShadow: 'none', maxHeight: '800px', marginTop: '20px'}}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{...styleTableHead, maxWidth: '80px', padding: '10px 0 10px 10px'}}>Nivel de Acceso</TableCell>
                <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Habilitado</TableCell>
                <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Comisión</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {
                levelAccess && Object.keys(levelAccess)?.map((access, index) => (
                  <Row values={levelAccess} setNullAccess={setNullAccess} onChange={setLevelAccess} boolean={!editLevel} access={access} accessValue={Object.values(levelAccess)[index] === null ? 'denegado' : Object.values(levelAccess)[index]} index={index} />
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
         
      </>,
      xs: 12
    }
  ];

  return data;
};

export default levelAccess;


export const Row = ({values, onChange, setNullAccess, boolean, access, accessValue, index}) => {
  const checkNumber = /^[0-9/ -()]+$/;
  const [error, setError] = useState(false);
  const {setDisableSaveButton} = useContext(DispatchErrorContext);


  useEffect(() => {
    if((accessValue !== 'denegado' && !checkNumber.test(accessValue) || accessValue === '')) {
      setDisableSaveButton(true);
      setError(true);
    } else {
      setDisableSaveButton(false);
      setError(false);
    }
  },[accessValue]);

  const handleBoolean = (e) => {
    let value = e.target.checked;
    if(!value) return onChange(setNullAccess({...values, [access]: 'denegado'}));
    return onChange(setNullAccess({...values, [access]: '0'}));
  };

  return(
    <TableRow key={index} sx={{ '&>td': { borderBottom: 'unset' }, borderRadius: 'inherit', backgroundColor: '#fff',  '&:hover': {
      background: '#efefef'
    }}} >
      <TableCell component="th" scope="row" style={{...styleTabbleData, padding: '0 100px 0 10px', fontSize: '1.1em'}}>{access.toUpperCase()}</TableCell>
      <TableCell style={{ padding: '0 0 0 2px'}}>
        <FormControl
          // defaultValue={accessValue}
          // defaultChecked={accessValue}

        >
          <Checkbox
            // defaultChecked={accessValue}
            // defaultValue={accessValue}
            checked={values[access] === 0 ? true : values[access]}
            onChange={handleBoolean}
            disabled={boolean}
          />
        </FormControl>
      </TableCell>
      <TextField 
        type="tel" 
        InputProps={{
          startAdornment: accessValue !== 'denegado' && 
        <MdOutlineAttachMoney size="23px" />
        }} 
        onChange={(e) => setNullAccess(onChange({...values, [access]: e.target.value}))} 
        disabled={boolean || accessValue === 'denegado'} 
        variant="outlined" 
        label={access} 
        value={accessValue} 
        error={error}
        helperText={error ? 'El campo solo puede contener números' : null}
        InputLabelProps={{shrink: true}} 
        style={{width: '100%', maxWidth: '200px'}} 
      />
    </TableRow>
  );
};
