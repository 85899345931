import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Show,  useGetOne,  usePermissions,  useRecordContext,  useResourceContext,  useUpdate } from 'react-admin';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import { bannerStyle } from '../../Theme/useStyle';
import CorporativoHeader from './SectionsShow/CorporativoHeader';
import PlanCard from './SectionsShow/PlanCard';
import CouponsCard from './SectionsShow/CouponsCard';
import QuestionsCard from './SectionsShow/QuestionsCard';
import WhitelistCard from './SectionsShow/WhitelistCard';
import ImagesCard from './SectionsShow/ImagesCard';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import VersionCard from '../../Components/UI/Show/VersionCard';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import { createNotification, getPermissions, handleClickCopy } from '../helpers/helpFunctions';
import ModalCoupons from '../../Components/UI/ModalCoupons';
import  ModalCreateCodigos  from '../../Components/UI/ModalCreateCodigos';
import { merchantCadena, merchantContext } from '../../DataProvider';
import { useQuery } from 'react-query';

const CorporativoShow = (props) => {
  const record = useRecordContext();
  const [slugValue, setSlugValue] = useState('');
  const [editHaberes, setEditHaberes] = useState(record?.['porcentaje-descuento-empleado'] && (record?.['porcentaje-descuento-empleado'] === 100) ? true : false);
  const [editPrice, setEditPrice] = useState(null);
  const { getMerchant } = useContext(merchantContext);
  const isSede = getMerchant()?.id !== '' && !getMerchant()?.negocio_central;
  const isOwner = getMerchant()?.id === '';
 

  useEffect(() => {
    record && setSlugValue(record.slug);
  },[]);
  
  return (
    <Show 
      {...props} 
      title={<TitleEdit title='Campaña: ' source='name'/>} 
      aside={<AsideShow getMerchant={getMerchant} isSede={isSede} />} 
      actions={null}
    >
      <ShowTransparent>
        <ShowCorporativo
          getMerchant={getMerchant}
          isSede={isSede}
          editHaberes={editHaberes}
          setEditPrice={setEditPrice}
          setSlugValue={setSlugValue}
          isOwner={isOwner}
          slugValue={slugValue}
          setEditHaberes={setEditHaberes}
          editPrice={editPrice}
        />
      </ShowTransparent>
    </Show>
  );
};

const AsideShow = ({getMerchant, isSede, ...rest}) => {
  const [open, setOpen] = useState(false);
  const [openCodigos, setOpenCodigos] = useState(false);
  const record = useRecordContext();
  const basePath = useResourceContext();
  const [update, {loaded, loading, error}] = useUpdate();
  const { data: merch } = useGetOne('merchants', {id: record?.merchant_id});
  const handleArchived = () => {
    const diff = {archivado: !record?.archivado};
    update('corporativo', {id: record?.id, data: diff, previousData: record});
  };

  const baseUrl = process.env.REACT_APP_TEST === 'true' ? `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/` : `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/`;

  useEffect(()=> {
    if(loaded){
      record.archivado ? 
        createNotification('success', 'Campaña Archivada con éxito') : 
        createNotification('success', 'Campaña Desarchivada con éxito'); 
    }else if(error)(
      record.archivado ? 
        createNotification('error', 'Error al Desarchivar la Campaña', ()=> handleClickCopy(record)) :
        createNotification('error', 'Error al Archivarla Campaña', ()=> handleClickCopy(record)) 
    );
  }, [loaded, error, record]);

  const handleLink = (tipo, merchId) => {
    if (merchId !== '' && merchId !== merchantCadena && ['cadena-habilitadas', 'cadena-sede'].includes(tipo)) {
      return '?seller_merchant='+ merchId;
    }
    return '';
  };
  const isDevelop = process.env.REACT_APP_TEST === 'true';

  const data = record ? 
    [
      // { link: basePath + '/' + record?.id, text: 'Editar Campaña', icon: <EditIcon/> },
      // { divider: true },
      (!isSede || merch?.negocio_central) && (!['local','cadena-habilitadas', 'cadena-sede'].includes(record?.['tipo'])) && {
        icon: <RemoveRedEyeOutlinedIcon style={{fontSize: '17px'}}/>,
        href: isDevelop ? `https://develop.sportclub.com.ar/corporativo/${record.slug}`:`https://sportclub.com.ar/corporativo/${record.slug}`,
        text: 'Ver Landing'
      },
      {
        icon: <RemoveRedEyeOutlinedIcon style={{fontSize: '17px'}}/>,
        // href: `https://www.sportclub.pagar.club/paso2/${record.slug}${(getMerchant().id !== '' && getMerchant()?.id !== merchantCadena) ? `?seller_merchant=${getMerchant().id}` : ''}`,
        href: isDevelop ? `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/${record.slug}${handleLink(record?.tipo, getMerchant()?.id)}` : `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/${record.slug}${handleLink(record?.tipo, getMerchant()?.id)} `,
        text: 'Ver Checkout'
      },
      { divider: true },
      {
        icon: <AddOutlinedIcon style={{fontSize: '18px'}} />,
        text: 'Agregar Cupón',
        onClick: () => setOpen(true)
      },
      {
        icon: <AddOutlinedIcon style={{fontSize: '18px'}} />,
        text: 'Crear Códigos',
        onClick: () => setOpenCodigos(true)
      },
      {
        icon: loading ? <CircularProgress style={{width: '18px', height: '18px'}}/> :
          record?.archivado ? 
            <UnarchiveOutlinedIcon style={{fontSize: '16px', marginRight: '2px'}}/> :
            <ArchiveOutlinedIcon  style={{fontSize: '16px', marginRight: '2px'}}/>,
        disabled: loading,
        onClick: handleArchived,
        text: record?.archivado ? 'Desarchivar Campaña' : 'Archivar Campaña'
      },
      { divider: true },
      {
        rest: rest,
        label: 'Eliminar Campaña',
        confirmTitle: `¿Eliminar ${record?.name}?`,
        confirmContent: '¿Seguro quieres eliminar esta campaña?',
        record: record,
        undoable:true,
        basePath: basePath,
      },
      {
        custom: true,
        component: <ModalCoupons record={record} open={open} setOpen={setOpen} key={Math.random()}/>
      },
      {
        custom: true,
        component: <ModalCreateCodigos record={record} openCodigos={openCodigos} setOpenCodigos={setOpenCodigos} key={Math.random()}/>
      }
    ] : 
    [];

  return (
    <AsideActionsShow data={data}/>
  );
};

export const ShowCorporativo = ({isOwner,getMerchant,  isSede, editHaberes, setEditPrice, setSlugValue, slugValue, setEditHaberes, editPrice}) => {

  const record = useRecordContext();
  const { data: merch } = useGetOne('merchants', {id: record?.merchant_id});
  const permission = getPermissions();
  const permisToEdit = useMemo(() => {
    const contextId = getMerchant()?.id;
    const corpMerch = record?.merchant_id;
    switch(true){
    case ['owner', 'superadmin'].includes(permission):
      return true;
    case [contextId, corpMerch].includes(merchantCadena):
      return true;
    case permission === 'admin' && getMerchant()?.negocio_central && record?.tipo === 'central':
      return true;
    case permission === 'admin' && [record?.merchant_id].includes(contextId) && record?.tipo === 'local':
      return true;
    default:
      return false;
      
    }
  },[merch, getMerchant()?.id]);

  return (
    <>
      <Box style={bannerStyle}/>
      <CorporativoHeader permisToEdit={permisToEdit} isSede={!merch?.negocio_central} editHaberes={editHaberes} setEditPrice={setEditPrice} setSlugValue={setSlugValue} slugValue={slugValue} />
      <PlanCard permisToEdit={permisToEdit} isSede={!merch?.negocio_central} setSlugValue={setSlugValue} setEditHaberes={setEditHaberes} editPrice={editPrice} slugValue={slugValue} />
      <CouponsCard/>
      <WhitelistCard/>
      {(!isOwner ? !isSede : merch?.negocio_central) && <QuestionsCard/>}
      {(!isOwner ? !isSede : merch?.negocio_central) && <ImagesCard/>}
      <VersionCard/>
    </>
  );
};

export default CorporativoShow;